<template>
  <div>
    <v-container>
      <!-- 消息条 -->
      <v-snackbar
        :timeout="1500"
        class="mt-4"
        v-model="notiBar.enabled"
        top
        transition="slide-y-transition"
        :color="notiBar.color"
        center
        text
        style="top: 56px"
      >
        {{ notiBar.text }}
      </v-snackbar>
      <v-card>
        <v-card-title>
          <v-btn depressed to="/" class="mx-3"
            ><v-icon>mdi-arrow-left-thick</v-icon>返回</v-btn
          >
          账号安全
        </v-card-title>
      </v-card>
      <v-card class="my-5">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title> 当前已登录 </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="" icon @click="get_online_data">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">设备</th>
                <th class="text-left">浏览器</th>
                <th class="text-left">登录时间</th>
                <th class="text-left">IP</th>
                <th class="text-left">操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in onlineData.list" :key="i">
                <td v-html="get_platform_icon(item.platform)"></td>
                <td>{{ item.browser }}</td>
                <td>{{ item.login_time }}</td>
                <td>{{ item.ip_info }}</td>
                <td>
                  <v-btn depressed v-if="item.token != myToken" @click="kick_online(i)">吊销</v-btn>
                  <v-btn depressed v-else disabled>本机</v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div class="text-caption text-right">
            数据刷新存在10s左右延迟。
          </div>
        </v-card-text>
      </v-card>
      <v-card class="my-5">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title> 修改密码 </v-toolbar-title>
        </v-app-bar>
        <v-card-text>
          <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editPwd.pass"
                  label="新密码"
                  prepend-inner-icon="mdi-key"
                  type="password"
                  hint="建议使用复杂密码，长度需大于6位。"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editPwd.pass1"
                  label="确认新密码"
                  hint="再次输入新密码"
                  type="password"
                  prepend-inner-icon="mdi-key"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="change_password">修改</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="mx-auto">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title> 登录记录 </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-btn color="" icon @click="refresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text>
          <div class="d-flex flex-wrap my-5">
            <v-btn
              rounded
              depressed
              color="primary"
              small
              class="ma-1"
              :disabled="search == ''"
              @click="search = ''"
              >清除</v-btn
            >
            <v-btn
              rounded
              depressed
              small
              class="ma-1"
              :disabled="search == today_date"
              @click="search = today_date"
              >今天的</v-btn
            >
            <v-btn
              rounded
              depressed
              small
              class="ma-1"
              :disabled="search == '微信'"
              @click="search = '微信'"
              >微信</v-btn
            >
            <v-btn
              rounded
              depressed
              small
              class="ma-1"
              :disabled="search == '❌'"
              @click="search = '❌'"
              >失败的记录</v-btn
            >
          </div>
          <v-data-table
            :headers="headers"
            :items="list"
            :search="search"
            :loading="loading"
            loading-text="正在获取数据。。。"
          ></v-data-table>
          <v-divider></v-divider>
          <div class="text-caption text-right">
            仅展示最近的50条记录，IP归属地仅供参考。
          </div>
        </v-card-text>
      </v-card>


      <!-- 提示框 -->
      <v-dialog v-model="alert.alert" max-width="450px">
        <v-card>
          <v-card-title>{{ alert.alert_type }}</v-card-title>
          <v-card-text>
            {{ alert.alert_text }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="alert.alert = !alert.alert"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "security",
  data: function () {
    return {
      search: "",
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "时间", value: "date_time" },
        { text: "结果", value: "result" },
        { text: "登录类型", value: "login_type" },
        { text: "IP", value: "ip" },
        { text: "IP属地", value: "ip_info" },
        { text: "访问路径", value: "call_back_param" },
        { text: "UA", value: "user_agent" },
      ],
      list: [],
      onlineData: {
        count: 0,
        list: null,
      },
      loading: false,
      alert: {
        alert_text: "",
        alert_type: "info",
        alert: false,
      },
      notiBar: {
        enabled: false,
        color: "",
        text: "",
      },
      editPwd:{
        pass:"",
        pass1:""
      }
    };
  },
  mounted: function () {
    this.get_data();
    this.get_online_data();
  },
  methods: {
    get_data: function () {
      var _this = this;
      this.loading = true;
      this.axios
        .get("/api/user/loginLog")
        .then((res) => {
          if (res.data.status < 0) {
            this.error(this.parseError(res));
            return;
          }
          _this.list = res.data.data.list;
          _this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    get_online_data: function () {
      var _this = this;
      this.axios
        .get("/api/user/online")
        .then((res) => {
          if (res.data.status < 0) {
            this.errorBar(this.parseError(res));
            return;
          }
          if (_this.onlineData.list != null) {
            _this.successBar("刷新成功");
          }
          _this.onlineData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    refresh: function () {
      this.list = [];
      this.get_data();
    },
    kick_online: function(index){
      var _this = this;
      this.axios({
        method: "post",
        url: "/api/user/kickOnline",
        data: {
          token: this.onlineData.list[index].token,
          op_key:this.onlineData.list[index].op_key,
        },
      }).then((res) => {
          if (res.data.status < 0) {
            this.errorBar(this.parseError(res));
            return;
          }
          _this.success("吊销成功");
          _this.get_online_data();
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    change_password: function(){
      if(this.editPwd.pass!=this.editPwd.pass1){
        this.error("两次输入的密码不一致");
        return;
      }
      var _this = this;
      this.axios({
        method: "post",
        url: "/api/user/changePassword",
        data: {
          new_password: this.editPwd.pass,
        },
      }).then((res) => {
          if (res.data.status < 0) {
            _this.error(_this.parseError(res));
            return;
          }
          _this.editPwd.pass="";
          _this.editPwd.pass1="";
          _this.successBar("修改成功");
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    get_platform_icon: function (platform) {
      switch (platform) {
        case "Windows":
          return '<svg t="1674788585369" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2690" width="48" height="48"><path d="M0 139.392L409.429333 81.92l0.170667 407.210667-409.216 2.389333L0 139.392z m409.301333 395.818667L409.6 942.08 0 884.181333V532.48l409.301333 2.730667z m41.258667-454.186667L1024 0v487.125333l-573.44 4.394667V81.024zM1024 533.333333L1023.872 1024l-572.501333-79.274667-0.810667-412.245333 573.44 0.896z" fill="#0078D7" p-id="2691"></path></svg>';
        case "Macintosh":
          return '<svg t="1674788639447" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6004" width="48" height="48"><path d="M960 108.8c35.2 0 64 28.8 64 64v518.4c0 35.2-28.8 64-64 64H646.4l57.6 115.2c6.4 16 0 35.2-12.8 41.6-3.2 3.2-9.6 3.2-12.8 3.2H348.8c-19.2 0-32-12.8-32-32 0-6.4 0-9.6 3.2-12.8l57.6-115.2H64c-35.2 0-64-28.8-64-64V172.8c0-35.2 28.8-64 64-64h896zM598.4 294.4c-35.2-3.2-67.2 19.2-86.4 19.2-16 0-41.6-19.2-70.4-19.2-35.2 0-70.4 22.4-89.6 54.4-38.4 64-9.6 163.2 25.6 217.6l6.4 9.6c16 22.4 35.2 48 60.8 44.8 28.8 0 38.4-19.2 70.4-19.2 32 0 41.6 19.2 70.4 16 28.8 0 48-25.6 67.2-54.4s28.8-60.8 28.8-60.8h-3.2C665.6 496 624 473.6 624 416c0-54.4 44.8-80 48-83.2-19.2-32-57.6-38.4-73.6-38.4z m0-105.6c-22.4 0-48 16-64 32-12.8 16-25.6 41.6-22.4 67.2 25.6 0 48-12.8 64-32 12.8-16 25.6-41.6 22.4-67.2z" fill="#888888" p-id="6005"></path></svg>';
        case "iPhone":
          return '<svg t="1674788673310" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7142" width="48" height="48"><path d="M682.666667 42.666667H341.333333a128 128 0 0 0-128 128v682.666666a128 128 0 0 0 128 128h341.333334a128 128 0 0 0 128-128V170.666667a128 128 0 0 0-128-128z m0 853.333333H341.333333a42.666667 42.666667 0 0 1-42.666666-42.666667V170.666667a42.666667 42.666667 0 0 1 42.666666-42.666667 42.666667 42.666667 0 0 1 42.666667 42.666667 42.666667 42.666667 0 0 0 42.666667 42.666666h170.666666a42.666667 42.666667 0 0 0 42.666667-42.666666 42.666667 42.666667 0 0 1 42.666667-42.666667 42.666667 42.666667 0 0 1 42.666666 42.666667v682.666666a42.666667 42.666667 0 0 1-42.666666 42.666667z" p-id="7143" fill="#707070"></path></svg>';
        default:
          return '<svg t="1674788695196" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8204" width="48" height="48"><path d="M899.544477 127.981836 123.664507 127.981836c-32.962739 0-59.683311 28.626991-59.683311 63.943334l0 447.601294c0 35.31532 26.720571 63.943334 59.683311 63.943334l358.098841 0-119.366621 191.82898 298.41553 0-119.366621-191.82898 358.098841 0c32.962739 0 59.683311-28.626991 59.683311-63.943334L959.227788 191.925171C959.227788 156.608827 932.508239 127.981836 899.544477 127.981836zM899.544477 575.583131c0 35.31532-26.720571 63.943334-59.683311 63.943334L183.347818 639.526465c-32.962739 0-59.683311-28.626991-59.683311-63.943334L123.664507 255.867482c0-35.31532 26.720571-63.943334 59.683311-63.943334L839.862189 191.924147c32.962739 0 59.683311 28.626991 59.683311 63.943334L899.5455 575.583131z" p-id="8205" fill="#8a8a8a"></path></svg>';
      }
    },
    success: function (text) {
      this.alert.alert = !this.alert.alert;
      this.alert.alert_text = text;
      this.alert.alert_type = "成功";
    },
    error: function (err) {
      this.alert.alert = !this.alert.alert;
      this.alert.alert_text = err;
      this.alert.alert_type = "错误";
    },
    successBar: function (text) {
      this.notiBar.enabled = !this.notiBar.enabled
      this.notiBar.text = text;
      this.notiBar.color = "success";
    },
    errorBar: function (err) {
      this.notiBar.enabled = !this.notiBar.enabled
      this.notiBar.text = err;
      this.notiBar.color = "error";
    },
  },
  computed: {
    today_date: function () {
      var date = new Date();
      var myyear = date.getFullYear();
      var mymonth = date.getMonth() + 1;
      var myweekday = date.getDate();

      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    },
    myToken: function(){
      return this.cookie.get("token");
    }
  },
};
</script>

<style></style>
